<template>
    <div class="h-[572px]">
        <div class="w-screen absolute left-0 z-0 bg-[repeating-linear-gradient(_#eeeeef_1px,_transparent_2px,_transparent_49px)] mt-11 h-[85%] tablet-landscape:hidden"></div>
        <img src="/images-new/prospecting/hero-header.svg" alt="Hero header" class="tablet:hidden">
        <div class="flex relative">
            <div class="mobile:hidden z-10 relative left-0 top-0 after:w-5 after:content-[''] after:absolute after:h-full after:bg-gradient-to-r after:from-grey-transparent-200 after:to-transparent after:top-0 after:left-full">
                <img :src="'/images-new/prospecting/hero-fixed-section.' + language + '.svg'" alt="Hero fixed section" class="">
            </div>
            <img :src="'/images-new/prospecting/hero-scroll-section.' + language + '.svg'" alt="Hero scroll section" class="mobile:relative absolute left-0 top-0 z-0 h-full max-w-none" :style="translateString">
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: "prospecting-hero",
    props: {
        language: String  
    },
    data() {
        return {
            scrollOffset: 0
        }
    },
    computed: {
        translateString(): string {
            return `transform: translateX(-${this.scrollOffset}px)`;
        }  
    },
    mounted() {
        document.addEventListener('scroll', () => {
            this.scrollOffset = window.scrollY / 4;
        });
    }
});
</script>